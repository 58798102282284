$(function () {

  //-----------------------
  //Fade In / Contents Slide Up
  //-----------------------
  $('body').scrollTop(0);

  //-----------------------
  //Star
  //-----------------------
  $('.message_star').on('click', function(){
    if ($(this).hasClass('starred')) {
      $(this).removeClass('starred');
    } else {
      $(this).addClass('starred');
    }
  });
  
  //-----------------------
  //Message Box
  //-----------------------
  $('.message_box').on('click', function(){
    $(this).removeClass('message_box_show',200);
  });

  //-----------------------
  //Smooth Scroll
  //-----------------------
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  //-----------------------
  //Navigation
  //-----------------------
  $('.toggle-btn').click(function() {
    $(this).toggleClass('active');

    if ($(this).hasClass('active')) {
      $('.header-nav').addClass('active');
    } else {
      $('.header-nav').removeClass('active');
    }
  });

  $('.header-nav ul li a').click(function() {
    $('.toggle-btn').toggleClass('active');

    if ($(this).hasClass('active')) {
      $('.header-nav').addClass('active');
    } else {
      $('.header-nav').removeClass('active');
    }
  });

  //-----------------------
  //Navigation Fixed
  //-----------------------
  var $win = $(window),
      $header = $('header'),
      animationClass = 'is-animation';

  $win.on('load scroll', function() {
    var value = $(this).scrollTop();
    if ( value > 100 ) {
      $header.addClass(animationClass);
    } else {
      $header.removeClass(animationClass);
    }
  });
  
});
